"use client";

import React from "react";
import { Question } from "@/lib/types";

export type FaqAccordionProps = {
  faqQuestions: Question[];
};

const FaqAccordion: React.FC<FaqAccordionProps> = ({ faqQuestions }) => {
  // Initialize an object to manage open states, with keys as question indexes
  const [openStates, setOpenStates] = React.useState<{ [key: number]: boolean }>({ 0: true });

  const toggleItem = (index: number) => {
    setOpenStates((prev) => ({
      ...prev,
      [index]: !prev[index], // Toggle the state for the clicked item
    }));
  };

  // console.log(faqQuestions);

  return (
    <div className="mx-auto mt-8 max-w-3xl space-y-4 md:mt-16">
      {faqQuestions?.map((question, index) => (
        <div
          key={index}
          className="cursor-pointer border border-gray-200 bg-base-100 transition-all duration-200 hover:bg-base-200"
        >
          <button
            type="button"
            className="flex w-full items-center justify-between px-4 py-5 sm:p-6"
            onClick={() => toggleItem(index)}
          >
            <span className="text-lg font-semibold">{question.question}</span>
            {openStates[index] ? (
              <svg
                className="size-6 rotate-180 text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
              </svg>
            ) : (
              <svg
                className="size-6 text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
              </svg>
            )}
          </button>
          <div className={`px-4 pb-5 sm:px-6 sm:pb-6 ${openStates[index] ? "" : "hidden"}`}>{question.answer}</div>
        </div>
      ))}
    </div>
  );
};

export default FaqAccordion;
