"use client";
import React, { useEffect, useRef, useState } from "react";

interface InViewComponentProps {
  onInView: () => void;
  children: React.ReactNode;
}

const List = ({ text }: { text: string }) => (
  <p className="text-body-color flex items-center text-base font-medium sm:text-lg">
    <input type="checkbox" defaultChecked readOnly className="checkbox-primary checkbox mr-2" />
    {text}
  </p>
);

const InViewComponent: React.FC<InViewComponentProps> = ({ onInView, children }) => {
  const elementRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && entry.intersectionRatio >= 0.8) {
            onInView();
          }
        });
      },
      { threshold: 0.8 }
    );

    if (elementRef.current) {
      observer.observe(elementRef.current);
    }

    return () => {
      if (elementRef?.current) {
        observer.unobserve(elementRef?.current!);
      }
    };
  }, [onInView]);

  return <div ref={elementRef}>{children}</div>;
};

export type FeaturesProps = {
  features: {
    title: string;
    description: string;
    list: string[];
    imageSrc: string;
  }[];
};

const ScrollableFeatures: React.FC<FeaturesProps> = ({ features }) => {
  const videoRefDesktop = useRef<HTMLVideoElement | null>(null);
  // const [selectedVideo, setSelectedVideo] = useState<string | null>(null);

  const handleInView = (index: number) => {
    const feature = features[index];
    if (videoRefDesktop.current) {
      videoRefDesktop.current.src = feature.imageSrc;
      videoRefDesktop.current.play().catch((error) => {
        console.error("Error attempting to play the video:", error);
      });
    }
  };

  return (
    <div className="container relative flex max-w-7xl flex-col sm:flex-row">
      <div className="left-content w-full pr-0 sm:w-2/5 sm:pr-5">
        {features.map((feature, index) => (
          <InViewComponent key={feature.title} onInView={() => handleInView(index)}>
            <div className="section mb-10 sm:mb-20 sm:h-[70vh]">
              <h3 className="mt-4 text-2xl font-bold sm:text-3xl lg:text-4xl">{feature.title}</h3>
              <p className="my-4 text-sm sm:text-base lg:text-xl">{feature.description}</p>
              <div className="wow fadeInUp container mx-auto mb-12 max-w-[570px] lg:mb-0">
                <div className="flex flex-col space-y-4">
                  {feature.list?.map((text: string) => <List text={text} key={text} />)}
                </div>
              </div>
              <div className="relative block sm:hidden">
                <div className="absolute -inset-0.5 z-[-1] rounded-lg bg-pink-600 bg-gradient-to-r from-purple-500 via-pink-500 to-red-500 opacity-50 blur-md"></div>
                <video loop autoPlay muted className="h-auto w-full rounded-md" src={feature.imageSrc}>
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          </InViewComponent>
        ))}
      </div>
      <div className="relative hidden w-full rounded-md sm:block">
        <div className="right-fixed-image sticky top-[120px] sm:top-[120px]">
          <div className="absolute -inset-0.5 z-[-1] rounded-lg bg-pink-600 bg-gradient-to-r from-purple-500 via-pink-500 to-red-500 opacity-50 blur-md"></div>
          <video
            ref={videoRefDesktop}
            loop
            autoPlay
            muted
            className="h-auto w-full rounded-md"
            src={features[0].imageSrc}
          >
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </div>
  );
};

export default ScrollableFeatures;
