"use client";

import { BlurImage } from "@/components/BlueImage";
import classNames from "classnames";
import { Theme } from "daisyui";
import React from "react";
import useBreakpoints from "../../hooks/useBreakpoints";

export type TestimonialUserProps = {
  name: string;
  designation?: string;
  description: string;
  imageSrc?: string;
  rating?: number;
  link?: string;
};

export type TestimonialGridProps = {
  theme?: Theme;
  title: string;
  description: string;
  users: TestimonialUserProps[];
};

// <div className="w-full">
//   <TestimonialGrid
//     title="Words from Others"
//     users={[
//       {
//         rating: 5,
//         imageSrc:
//           "https://images.unsplash.com/photo-1611432579699-484f7990b127?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8aGVhZHNob3R8ZW58MHx8MHx8fDA%3D",
//         description:
//           "You made it so simple. My new site is so much faster and easier to work with than my old site. I just choose the page, make the change.",
//         name: "Emily Doe",
//         designation: "CEO of TravelEase",
//       },
//       {
//         rating: 5,
//         imageSrc:
//           "https://images.unsplash.com/photo-1633332755192-727a05c4013d?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8aGVhZHNob3R8ZW58MHx8MHx8fDA%3D",
//         description:
//           "Simply the best. Better than all the rest. I’d recommend this product to beginners and advanced users.",
//         name: "Jacob Jones",
//         designation: "Digital Marketer",
//       },
//       {
//         rating: 5,
//         imageSrc:
//           "https://images.unsplash.com/photo-1494790108377-be9c29b29330?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fGhlYWRzaG90fGVufDB8fDB8fHww",
//         description:
//           "I cannot believe that I have got a brand new landing page after getting Omega. It was super easy to edit and publish.",
//         name: "Jenny Wilson",
//         designation: "Graphic Designer",
//       },
//       {
//         rating: 4,
//         imageSrc:
//           "https://images.unsplash.com/photo-1531746020798-e6953c6e8e04?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8cG9ydHJhaXR8ZW58MHx8MHx8fDA%3D",
//         description:
//           "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod quam eget sem auctor lacinia.",
//         name: "John Smith",
//         designation: "Founder of Tech Innovators",
//       },
//       {
//         rating: 5,
//         imageSrc:
//           "https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8cG9ydHJhaXR8ZW58MHx8MHx8fDA%3D",
//         description:
//           "Aenean nec eleifend arcu. Sed euismod ligula eu felis sollicitudin, nec consectetur orci convallis.",
//         name: "Alice Johnson",
//         designation: "Marketing Manager at XYZ Inc",
//       },
//       {
//         rating: 3,
//         imageSrc:
//           "https://images.unsplash.com/photo-1544005313-94ddf0286df2?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8cG9ydHJhaXR8ZW58MHx8MHx8fDA%3D",
//         description: "Fusce rhoncus mi non lacus fermentum, a finibus urna venenatis. Nulla facilisi.",
//         name: "David Wilson",
//         designation: "CTO at InnovateTech",
//       },
//       {
//         rating: 2,
//         imageSrc:
//           "https://images.unsplash.com/photo-1521119989659-a83eee488004?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8cG9ydHJhaXR8ZW58MHx8MHx8fDA%3D",
//         description: "Pellentesque eget ex at ante tincidunt facilisis vel a metus.",
//         name: "Olivia Brown",
//         designation: "Product Manager at Global Corp",
//       },
//       {
//         rating: 4,
//         imageSrc:
//           "https://images.unsplash.com/photo-1567532939604-b6b5b0db2604?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTF8fHBvcnRyYWl0fGVufDB8fDB8fHww",
//         description: "Suspendisse vehicula elit a nunc fermentum, nec vehicula metus lacinia.",
//         name: "Sophia White",
//         designation: "Director of Operations at TechSolutions",
//       },
//       {
//         rating: 3,
//         imageSrc:
//           "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTR8fHBvcnRyYWl0fGVufDB8fDB8fHww",
//         description: "Vestibulum bibendum est eget lectus blandit, non blandit nunc vulputate.",
//         name: "James Miller",
//         designation: "Creative Director at CreativeWorks",
//       },
//       {
//         rating: 5,
//         imageSrc:
//           "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fHBvcnRyYWl0fGVufDB8fDB8fHww",
//         description: "Ut non felis ac tellus congue venenatis. Sed nec justo quis libero tincidunt rhoncus.",
//         name: "Ella Davis",
//         designation: "CEO of InnovateHub",
//       },
//       {
//         rating: 4,
//         imageSrc:
//           "https://images.unsplash.com/photo-1504257432389-52343af06ae3?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTh8fHBvcnRyYWl0fGVufDB8fDB8fHww",
//         description: "Duis ultricies odio nec enim commodo interdum. Sed euismod quam eget sem auctor lacinia.",
//         name: "Liam Johnson",
//         designation: "Product Designer at DesignCraft",
//       },
//       {
//         rating: 3,
//         imageSrc:
//           "https://images.unsplash.com/photo-1529626455594-4ff0802cfb7e?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjZ8fHBvcnRyYWl0fGVufDB8fDB8fHww",
//         description:
//           "Praesent eu metus non massa aliquet tincidunt id ut turpis. Donec sodales, neque nec fermentum.",
//         name: "Ava Lee",
//         designation: "Marketing Specialist at MarketPro",
//       },
//     ]}
//     description="Our happy clients say about us"
//   />
// </div>

const TestimonialGrid: React.FC<TestimonialGridProps> = ({ title, description, theme, users }) => {
  const breakpoint = useBreakpoints();
  let userWindowSize = 3;
  if (breakpoint === "md") {
    userWindowSize = 2;
  }

  // Divide users into three groups using reduce
  const groups: TestimonialUserProps[][] = users?.reduce((acc, user, index) => {
    const groupIndex = Math.floor(index / (users.length / userWindowSize));
    if (!acc?.[groupIndex]) {
      // @ts-ignore
      acc[groupIndex] = [];
    }
    // @ts-ignore
    acc[groupIndex]?.push(user!);
    return acc;
  }, []);

  return (
    <section id="testimonies" className="py-20" data-theme={theme}>
      <div className="mx-8 max-w-6xl md:mx-10 lg:mx-20 xl:mx-auto">
        <div className="translate-x-0 translate-y-0 scale-100 opacity-100 transition duration-500 ease-in-out">
          <div className="mb-12 space-y-5 md:mb-16 md:text-center">
            <h2 className="mb-5 text-3xl font-semibold md:text-center md:text-5xl">{title}</h2>
            <p className="text-xl md:text-center md:text-2xl">{description}</p>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 lg:gap-8">
          {groups?.map((group, index) => (
            <div
              className={classNames(
                "space-y-8",
                index !== 0 && users?.length > userWindowSize ? "hidden sm:block" : ""
              )}
              key={index}
            >
              {group?.map((user) => (
                <div className="group relative" key={user.name}>
                  <div className="duration-400 absolute -inset-1 rounded-lg bg-gradient-to-r from-purple-600 to-pink-600 opacity-25 blur transition group-hover:opacity-100 group-hover:duration-200"></div>
                  {user.link && (
                    <a href={user.link} className="cursor-pointer">
                      <div className="relative space-y-6 rounded-lg bg-slate-800 p-6 leading-none ring-1 ring-gray-900/5">
                        <div className="flex items-center space-x-4">
                          <BlurImage
                            width={100}
                            height={100}
                            src={user.imageSrc!}
                            className="size-12 rounded-full border bg-cover bg-center object-fill"
                            alt={user.name}
                          />
                          <div>
                            <h3 className="text-lg font-semibold text-white">{user.name}</h3>
                            <p className="text-md text-gray-500">{user.designation}</p>
                          </div>
                        </div>
                        <p className="text-md leading-normal text-gray-300">{user.description}</p>
                      </div>
                    </a>
                  )}
                  {!user.link && (
                    <span className="cursor-pointer">
                      <div className="relative space-y-6 rounded-lg bg-slate-800 p-6 leading-none ring-1 ring-gray-900/5">
                        <div className="flex items-center space-x-4">
                          <BlurImage
                            width={100}
                            height={100}
                            src={user.imageSrc!}
                            className="size-12 rounded-full border bg-cover bg-center object-fill"
                            alt={user.name}
                          />
                          <div>
                            <h3 className="text-lg font-semibold text-white">{user.name}</h3>
                            <p className="text-md text-gray-500">{user.designation}</p>
                          </div>
                        </div>
                        <p className="text-md leading-normal text-gray-300">{user.description}</p>
                      </div>
                    </span>
                  )}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
      <div className="divider mt-5" />
    </section>
  );
};

export default TestimonialGrid;
