"use client";

import { useEffect, useState } from "react";

const getActiveBreakpoint = () => {
  if (window?.matchMedia("(min-width: 1280px)").matches) {
    return "xl";
  } else if (window?.matchMedia("(min-width: 1024px)").matches) {
    return "lg";
  } else if (window?.matchMedia("(min-width: 768px)").matches) {
    return "md";
  } else if (window?.matchMedia("(min-width: 640px)").matches) {
    return "sm";
  } else {
    return "default";
  }
};

const useBreakpoints = () => {
  const [activeBreakpoint, setActiveBreakpoint] = useState("lg");

  useEffect(() => {
    function handleResize() {
      const newBreakpoint = getActiveBreakpoint();
      if (newBreakpoint !== activeBreakpoint) {
        setActiveBreakpoint(newBreakpoint);
      }
    }

    window?.addEventListener("resize", handleResize);

    return () => {
      window?.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return activeBreakpoint;
};

export default useBreakpoints;
